import * as z from "zod";

export const PaymentInformationSchema = z.object({
  name: z.string().nonempty("Name is required"),
  lga: z
    .string()
    .nonempty("LGA is required")
    .refine((value) => value !== "Select LGA", {
      message: "Please select a valid LGA",
    }),
  payment_method: z.string().nonempty("Payment type is required"),
});
