import React from "react";
import NavTransparent from "../../components/nav-transparent";
import Hero from "./components/hero";
import SelectBankForm from "./components/select-bank-form";

const SelectBankPage = () => {
  return (
    <div
      style={{
        backgroundImage: `linear-gradient(to bottom, rgba(2, 43, 38, 0.5), rgba(2, 43, 38, 1)), url('/banner-image.png')`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        minHeight: "100vh",
        width: "100vw",
      }}
    >
      <NavTransparent />
      <Hero />

      <SelectBankForm />
    </div>
  );
};

export default SelectBankPage;
