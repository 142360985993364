import React, { useEffect, useState } from "react";
import InstallerReel from "../../../../components/installer-reel";
import {
  Installer,
  useSearchInstallersMutation,
} from "../../../../store/installersApiSlice";

const SimilarInstallers = ({
  installer_state,
}: {
  installer_state: string;
}) => {
  const [searchInstallers, { isLoading: searchInstallerLoading }] =
    useSearchInstallersMutation();

  const [installers, setInstallers] = useState<Installer[]>([]);

  const searchSimilarInstallers = async () => {
    return await searchInstallers({
      state: installer_state || null,
      search: null,
      limit: 10,
    }).unwrap();
  };

  useEffect(() => {
    const fetchInstallers = async () => {
      try {
        const response = await searchSimilarInstallers();
        setInstallers(response.data);
      } catch (error) {
        console.error("Error fetching similar installers:", error);
      }
    };

    fetchInstallers();
  }, [installer_state]);

  return (
    <InstallerReel
      installers={installers}
      title="Similar Installers"
      href="/featured-installers"
    />
  );
};

export default SimilarInstallers;
