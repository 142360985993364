import React from "react";
import NavFilled from "../../components/nav-filled";
import Hero from "./components/hero";
import FeaturedInstaller from "./components/featured-installers";
import NearbyInstaller from "./components/nearby-installer";

const InstallersPage = () => {
  return (
    <div className="h-full w-full">
      <NavFilled />
      <Hero />
      <FeaturedInstaller />
      {/* <NearbyInstaller /> */}
    </div>
  );
};

export default InstallersPage;
