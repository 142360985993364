import * as z from "zod";

export const CreateAccountSchema = z
  .object({
    email: z.string().email("Invalid email address"),
    phone: z
      .string()
      .length(11, "Phone number must be exactly 11 digits")
      .regex(/^[0-9]+$/, "Phone number can only contain digits"),
    password: z
      .string()
      .min(8, "Password must be at least 8 characters long")
      .regex(/[A-Za-z]/, "Password must contain at least one letter"), // New rule added
    confirm_password: z.string(),
    order_id: z.string().optional(), // Optional if not always required
  })
  .refine((data) => data.password === data.confirm_password, {
    message: "Passwords do not match",
    path: ["confirm_password"], // This refers to the field to highlight the error
  });
