import React, { useState } from "react";
import MaxWidthWrapper from "../../../../components/max-width-wrapper";
import imagesUrl from "../../../../assets/images/imagesUrl";
import { useNavigate } from "react-router-dom";

const Hero = () => {
  const navigate = useNavigate();
  return (
    <div className="w-full h-[400px] relative top-0   md:h-[510px] ">
      <div className=" w-full relative  h-full  mb-auto md:mb-0">
        <div className="w-[100%] md:ml-auto h-full bg-[#D9D9D9] relative">
          <img
            src={imagesUrl.ProfileBanner}
            className="mb-auto w-full h-full object-cover"
            alt="hero "
          />
          <div className="px-[16px] lg:px-[100px] py-[40px] lg:py-[80px] bg-transparent inset-0 w-full h-full z-[10] absolute ">
            <div
              onClick={() => navigate(-1)}
              className="cursor-pointer group w-fit flex items-center space-x-3"
            >
              <img
                src={imagesUrl.Back}
                alt="back"
                className="w-10 h-10 transition-transform transform group-hover:scale-90"
              />
              <p className="cursor-pointer text-[20px] text-white font-[400] group-hover:text-[#3EB555]  group-hover:border-b-[#3EB555]">
                Go Back
              </p>
            </div>
            <div className=" mt-20 ">
              <h1 className="text-[46px] lg:text-[72px] text-white">
                Installer Profile
              </h1>
            </div>
          </div>
          <div className="absolute inset-0 bg-[#000000] opacity-50" />
        </div>
      </div>
    </div>
  );
};

export default Hero;
