import * as z from "zod";

export const SelectPlanSchema = z
  .object({
    state: z.string(),
    installer: z.string(),
    useNationalGrid: z.string(),
    nepa_average_hour: z.string(),
    buildingType: z.string(),
    has_bedroom_with_tv: z.boolean(),
    has_bedroom_without_tv: z.boolean(),
    has_sitting_room: z.boolean(),
    has_other_places: z.string(),
    bedroom_with_tv_qty: z.string(),
    bedroom_with_tv_avhours: z.string(),
    sitting_room_qty: z.string(),
    sitting_room_avhours: z.string(),
    bedroom_without_tv_qty: z.string(),
    bedroom_without_tv_avhours: z.string(),
    entrance_room_qty: z.string(),
    entrance_room_avhour: z.string(),
    has_entrance_room: z.boolean(),
    has_dining: z.boolean(),
    dinning_qty: z.string(),
    dinning_avhours: z.string(),
    has_kitchen: z.boolean(),
    kitchen_qty: z.string(),
    kitchen_avhours: z.string(),
    has_home_office: z.boolean(),
    home_office_qty: z.string(),
    home_office_avhours: z.string(),
    has_garage: z.boolean(),
    garage_qty: z.string(),
    garage_avhours: z.string(),
    has_heavy_appliance: z.string(),
    has_fridge: z.string(),
    has_table_top_fridge: z.boolean(),
    table_top_fridge_qty: z.string(),
    table_top_fridge_avhours: z.string(),
    has_single_door_fridge: z.boolean(),
    single_door_fridge_qty: z.string(),
    single_door_fridge_avhours: z.string(),
    has_double_door_fridge: z.boolean(),
    double_door_fridge_qty: z.string(),
    double_door_fridge_avhours: z.string(),
    has_side_by_side_door_fridge: z.boolean(),
    side_by_side_door_fridge_qty: z.string(),
    side_by_side_door_fridge_avhours: z.string(),
    has_single_door_deep_freezer: z.boolean(),
    single_door_deep_freezer_qty: z.string(),
    single_door_deep_freezer_avhours: z.string(),
    has_double_door_deep_freezer: z.boolean(),
    double_door_deep_freezer_qty: z.string(),
    double_door_deep_freezer_avhours: z.string(),
    has_inverter_ac: z.string(),
    has_1hp_ac: z.boolean(),
    ac_1hp_qty: z.string(),
    ac_1hp_avhours: z.string(),
    has_15hp_ac: z.boolean(),
    ac_15hp_qty: z.string(),
    ac_15hp_avhours: z.string(),
    has_2hp_ac: z.boolean(),

    ac_2hp_qty: z.string(),
    ac_2hp_avhours: z.string(),
    has_2_5hp_ac: z.boolean(),

    ac_2_5hp_qty: z.string(),
    ac_2_5hp_avhours: z.string(),
    has_3hp_ac: z.boolean(),

    ac_3hp_qty: z.string(),
    ac_3hp_avhours: z.string(),

    has_microwave: z.string(),

    has_small_microwave: z.boolean(),
    small_microwave_qty: z.string(),

    small_microwave_avhours: z.string(),

    has_big_microwave: z.boolean(),

    big_microwave_qty: z.string(),

    big_microwave_avhours: z.string(),

    has_heater: z.string(),

    has_heater_1000w: z.boolean(),

    heater_1000w_qty: z.string(),
    heater_1000w_avhours: z.string(),
    has_heater_1500w: z.boolean(),

    heater_1500w_qty: z.string(),
    heater_1500w_avhours: z.string(),
    has_heater_2000w: z.boolean(),

    heater_2000w_qty: z.string(),
    heater_2000w_avhours: z.string(),

    has_pressing_iron: z.string(),
    has_small_pressing_iron: z.boolean(),

    small_pressing_iron_qty: z.string(),
    small_pressing_iron_avhours: z.string(),
    has_big_pressing_iron: z.boolean(),

    big_pressing_iron_qty: z.string(),
    big_pressing_iron_avhours: z.string(),

    has_sumo_pump: z.string(),

    has_sumo_1hp: z.boolean(),

    sumo_1hp_qty: z.string(),
    sumo_1hp_avhours: z.string(),
    has_sumo_1_5hp: z.boolean(),

    sumo_1_5hp_qty: z.string(),
    sumo_1_5hp_avhours: z.string(),
    has_sumo_2hp: z.boolean(),

    sumo_2hp_qty: z.string(),
    sumo_2hp_avhours: z.string(),

    has_sumo_2_5hp: z.boolean(),
    sumo_2_5hp_qty: z.string(),
    sumo_2_5hp_avhours: z.string(),
    has_sumo_3hp: z.boolean(),

    sumo_3hp_qty: z.string(),
    sumo_3hp_avhours: z.string(),
  })
  .superRefine((data, ctx) => {
    if (data.useNationalGrid === "Yes" && data.nepa_average_hour === "") {
      ctx.addIssue({
        code: "custom",
        path: ["nepa_average_hour"],
        message: "Nepa average hour is required when using National Grid",
      });
    }
  });
