import React, { useState } from "react";
import MaxWidthWrapper from "../../../../components/max-width-wrapper";
import imagesUrl from "../../../../assets/images/imagesUrl";
import { MapPin } from "lucide-react";
import { Rating } from "@mui/material";
import { Button } from "../../../../components/ui/button";
import { useNavigate, useParams } from "react-router-dom";
import { IdState, useIdStore } from "../../../../store/customer-store";

const Intro = ({ image, name, location }: { image: string , name:string, location:string}) => {
  const [value, setValue] = useState<number | null>(3);
  const navigate = useNavigate();
  const setInstallerId = useIdStore((state: IdState) => state.setInstallerId);
  const packageId = useIdStore((state: IdState) => state.readPackageId());

  const { installerId } = useParams<{ installerId: string }>();

  const handleOrderPackage = () => {
    setInstallerId(installerId ?? "");

    if (packageId) {
      navigate("/select-payment-plan");
    } else {
      navigate("/order-a-system");
    }
  };

  return (
    <MaxWidthWrapper className="mb-5 lg:mb-10">
      <div className="w-full grid grid-cols-1 sm:grid-cols-2 items-center relative mt-[-65px]  h-full">
        <div className="shadow-sm p-6 relative bg-white grid grid-cols-1 sm:grid-cols-2 gap-x-4 items-center h-[369px] sm:h-[231px]  border rounded-3xl z-[200]">
          <div className="aspect-w-16 aspect-h-9 h-[183px] sm:h-full w-[inherit] relative">
            <img
              className="w-full h-full object-cover rounded-[8px]"
              src={image}
              alt="installer thumbnail"
            />
          </div>

          <div>
            <div className="w-full flex flex-col items-start space-y-1">
              <p className="text-[37px] text-[#153954] text-left">{name}</p>
              <div className="bg-[#D0D7DD80] p-1 rounded-[4px]  flex space-x-1 items-center">
                <MapPin className="w-4 h-4" />
                <span className="text-sm font-medium text-[#3C5A71]">
                 {location}
                </span>
              </div>
            </div>
            {/* <div className="mt-2 flex items-center space-x-3">
              <Rating
                size="small"
                name="simple-controlled"
                value={value}
                onChange={(event, newValue) => {
                  setValue(newValue);
                }}
              />
              <span className="text-[#808489] text-xs">3/5</span>
            </div> */}
          </div>
        </div>

        <div className=" mt-4 sm:mt-0">
          <Button
            onClick={handleOrderPackage}
            size={"lg"}
            className="bg-[#3EB555] hover:bg-[#359C4A] "
          >
            Order Now
          </Button>
        </div>
      </div>
    </MaxWidthWrapper>
  );
};

export default Intro;
