import React from "react";
import NavFilled from "../../../components/nav-filled";
import Hero from "./components/Hero";
import Intro from "./components/Intro";
import BasicInformation from "./components/basic-information";
import Expertise from "./components/expertise";
import Socials from "./components/socials";
import Reviews from "./components/reviews";
import SimilarInstallers from "./components/similar-installers";
import { useGetInstallerQuery } from "../../../store/installersApiSlice";
import { useParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/query";
import imagesUrl from "../../../assets/images/imagesUrl";

const InstallerDetailPage = () => {
  const { installerId } = useParams<{ installerId: string }>();

  const { data, isLoading, refetch } = useGetInstallerQuery(
    installerId ? { uniq_id: installerId } : skipToken
  );

  const installer = data?.data;

  console.log(installer, "installer");

  return (
    <div>
      <NavFilled />
      <Hero />
      <Intro
        image={installer?.image ?? imagesUrl.DefaultImage}
        name={installer?.name ?? ""}
        location={installer?.state_name ?? ""}
      />
      <BasicInformation
        phone={installer?.phone ?? "Not Available"}
        email={installer?.email ?? "Not Available"}
        address={installer?.address ?? "Not Available"}
        response_time={installer?.response_time_name ?? "Not Available"}
        installations={installer?.installations ?? "Not Available"}
      />
      <Expertise
        expertise={installer?.expertise ?? []}
        about={installer?.about ?? "Not Available"}
      />
      <Socials
        facebook={installer?.facebook ?? ""}
        instagram={installer?.instagram ?? ""}
        youtube={installer?.youtube ?? ""}
        linkedin={installer?.linkedin ?? ""}
        twitter={installer?.twitter ?? ""}
        whatsapp={installer?.whatsapp ?? ""}
      />
      {/* <Reviews /> */}
      <SimilarInstallers installer_state={installer?.state_name || ""} />
    </div>
  );
};

export default InstallerDetailPage;
