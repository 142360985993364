import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

export type IdState = {
  stateId: string | null; // Changed from 'id' to 'stateId' for clarity
  stateName: string | null; // Changed from 'id' to 'stateId' for clarity
  lgaName: string | null; // Changed from 'id' to 'stateId' for clarity
  lgaId: string | null; // Changed from 'id' to 'stateId' for clarity
  installerId: string | null; // New field for installerId
  packageId: string | null; // New field for installerId
  orderId: string | null; // New field for installerId
  paymentTypeId: string | null; // New field for installerId
  customerName: string | null; // New field for installerId
  customerEmail: string | null; // New field for installerId
  customerPhone: string | null; // New field for installerId
  setStateId: (newId: string) => void; // Updated function name
  setStateName: (newId: string) => void; // Updated function name
  setLgaName: (newId: string) => void; // Updated function name
  setLgaId: (newId: string) => void; // Updated function name
  setInstallerId: (newId: string) => void; // Function to set installerId
  setPackageId: (newId: string) => void; // Function to set installerId
  setOrderId: (newId: string) => void; // Function to set installerId
  setPaymentTypeId: (newId: string) => void; // Function to set installerId
  setCustomerName: (newId: string) => void; // Function to set installerId
  setCustomerEmail: (newId: string) => void; // Function to set installerId
  setCustomerPhone: (newId: string) => void; // Function to set installerId
  removeStateId: () => void; // Updated function name
  removeStateName: () => void; // Updated function name
  removeLgaName: () => void; // Updated function name
  removeLgaId: () => void; // Updated function name
  removeInstallerId: () => void; // Function to remove installerId
  removePackageId: () => void; // Function to remove installerId
  removeOrderId: () => void; // Function to remove installerId
  removePaymentTypeId: () => void; // Function to remove installerId
  removeCustomerName: () => void; // Function to remove installerId
  removeCustomerEmail: () => void; // Function to remove installerId
  removeCustomerPhone: () => void; // Function to remove installerId
  readStateId: () => string | null; // Function to read stateId
  readStateName: () => string | null; // Function to read stateId
  readLgaName: () => string | null; // Function to read stateId
  readLgaId: () => string | null; // Function to read stateId
  readInstallerId: () => string | null; // Function to read installerId
  readPackageId: () => string | null; // Function to read installerId
  readOrderId: () => string | null; // Function to read installerId
  readPaymentTypeId: () => string | null; // Function to read installerId
  readCustomerName: () => string | null; // Function to read installerId
  readCustomerEmail: () => string | null; // Function to read installerId
  readCustomerPhone: () => string | null; // Function to read installerId
  clearAllStates: () => void; // New method to clear all states
};

export const useIdStore = create<IdState>()(
  persist(
    (set, get) => ({
      stateId: null,
      stateName: null,
      lgaName: null,
      lgaId: null,
      installerId: null,
      packageId: null,
      orderId: null,
      paymentTypeId: null,
      customerName: null,
      customerEmail: null,
      customerPhone: null,
      setStateId: (newId) => set({ stateId: newId }),
      setStateName: (newId) => set({ stateName: newId }),
      setLgaName: (newId) => set({ lgaName: newId }),
      setLgaId: (newId) => set({ lgaId: newId }),
      setInstallerId: (newId) => set({ installerId: newId }),
      setPackageId: (newId) => set({ packageId: newId }),
      setOrderId: (newId) => set({ orderId: newId }),
      setPaymentTypeId: (newId) => set({ paymentTypeId: newId }),
      setCustomerName: (newId) => set({ customerName: newId }),
      setCustomerEmail: (newId) => set({ customerEmail: newId }),
      setCustomerPhone: (newId) => set({ customerPhone: newId }),
      removeStateId: () => set({ stateId: null }),
      removeStateName: () => set({ stateName: null }),
      removeLgaName: () => set({ lgaName: null }),
      removeLgaId: () => set({ lgaId: null }),
      removeInstallerId: () => set({ installerId: null }),
      removePackageId: () => set({ packageId: null }),
      removeOrderId: () => set({ orderId: null }),
      removePaymentTypeId: () => set({ paymentTypeId: null }),
      removeCustomerName: () => set({ customerName: null }),
      removeCustomerEmail: () => set({ customerEmail: null }),
      removeCustomerPhone: () => set({ customerPhone: null }),
      readStateId: () => get().stateId,
      readStateName: () => get().stateName,
      readLgaName: () => get().lgaName,
      readLgaId: () => get().lgaId,
      readInstallerId: () => get().installerId,
      readPackageId: () => get().packageId,
      readOrderId: () => get().orderId,
      readPaymentTypeId: () => get().paymentTypeId,
      readCustomerName: () => get().customerName,
      readCustomerEmail: () => get().customerEmail,
      readCustomerPhone: () => get().customerPhone,
      clearAllStates: () => {
        set({
          stateId: null,
          stateName: null,
          lgaName: null,
          lgaId: null,
          installerId: null,
          packageId: null,
          orderId: null,
          paymentTypeId: null,
          customerName: null,
          customerEmail: null,
          customerPhone: null,
        });
      },
    }),
    {
      name: "id-storage", // Changed name for clarity
      storage: createJSONStorage(() => localStorage),
    }
  )
);

// Clear all state on page refresh
if (typeof window !== "undefined") {
  window.addEventListener("beforeunload", () => {
    const clearStore = useIdStore.getState().clearAllStates;
    clearStore();
  });
}
