import { apiSlice } from "./apiSlice";

const ORDER_URL = "/order";

export type State = {
  state_id: string;
  name: string;
};
export type FinInstitution = {
  id: string;
  uniq_id: string;
  name: string;
  interest_rate: string;
  live: string;
  img: string | null;
  date: string;
};
export type Lga = {
  local_id: string;
  name: string;
};

type StateResponse = {
  data: State[];
  message: string;
  status: boolean;
};
type FinInstitutionsResponse = {
  data: FinInstitution[];
  message: string;
  status: boolean;
};
type LgaResponse = {
  data: Lga[];
  message: string;
  status: boolean;
};

export type PackageDTO = {
  state: string;
  nepa_average_hour: number;
  bedroom_with_tv_qty: number;
  bedroom_with_tv_avhours: number;
  bedroom_without_tv_qty: number;
  bedroom_without_tv_avhours: number;
  sitting_room_qty: number | null;
  sitting_room_avhours: number | null;
  entrance_room_qty: number | null;
  entrance_room_avhour: number | null;
  dinning_qty: number | null;
  dinning_avhours: number | null;
  kitchen_qty: number | null;
  kitchen_avhours: number | null;
  home_office_qty: number | null;
  home_office_avhours: number | null;
  garage_qty: number | null;
  garage_avhours: number | null;
  table_top_fridge_qty: number | null;
  table_top_fridge_avhours: number | null;
  single_door_fridge_qty: number | null;
  single_door_fridge_avhours: number | null;
  double_door_fridge_qty: number | null;
  double_door_fridge_avhours: number | null;
  side_by_side_door_fridge_qty: number | null;
  side_by_side_door_fridge_avhours: number | null;
  single_door_deep_freezer_qty: number | null;
  single_door_deep_freezer_avhours: number | null;
  double_door_deep_freezer_qty: number | null;
  double_door_deep_freezer_avhours: number | null;
  ac_1hp_qty: number | null;
  ac_1hp_avhours: number | null;
  ac_15hp_qty: number | null;
  ac_15hp_avhours: number | null;
  ac_2hp_qty: number | null;
  ac_2hp_avhours: number | null;
  ac_2_5hp_qty: number | null;
  ac_2_5hp_avhours: number | null;
  ac_3hp_qty: number | null;
  ac_3hp_avhours: number | null;
  small_microwave_qty: number | null;
  small_microwave_avhours: number | null;
  big_microwave_qty: number | null;
  big_microwave_avhours: number | null;
  heater_1000w_qty: number | null;
  heater_1000w_avhours: number | null;
  heater_1500w_qty: number | null;
  heater_1500w_avhours: number | null;
  heater_2000w_qty: number | null;
  heater_2000w_avhours: number | null;
  small_pressing_iron_qty: number | null;
  small_pressing_iron_avhours: number | null;
  big_pressing_iron_qty: number | null;
  big_pressing_iron_avhours: number | null;
  sumo_1hp_qty: number | null;
  sumo_1hp_avhours: number | null;
  sumo_1_5hp_qty: number | null;
  sumo_1_5hp_avhours: number | null;
  sumo_2hp_qty: number | null;
  sumo_2_5hp_qty: number | null;
  sumo_2_5hp_avhours: number | null;
  sumo_3hp_qty: number | null;
  sumo_3hp_avhours: number | null;
};

export type OrderItem = {
  id: string;
  item_id: string;
  name: string;
  price: string;
  qty: string;
};

export type Order = {
  package_uniq_id: string;
  user_id: string;
  installer_uniq_id: string;
  amount: string;
  discount: string;
  lga_id: string;
  payment_type: string;
  name: string;
  paid: string;
  order_items: OrderItem[];
  date: string;
};

type Package = {
  id: string;
  uniq_id: string;
  package_name: string;
  package_load: string;
  package_description: string;
  package_items: {
    id: string;
    item_id: string;
    item_type: string;
    item_name: string;
    item_cost_price: string;
    item_sales_price: string;
    qty: string;
    type_data: {
      id: string;
      capacity?: string;
      phase?: string;
      battery_standard_voltage?: string;
      technology?: string;
      type?: string;
      standard_voltage?: string;
      current?: string;
      energy_density?: string;
      maximum_power?: string;
      maximum_open_circuit?: string;
      short_circuit_current?: string;
      optimum_operating_voltage?: string;
    } | null;
  }[];
  battery: {
    title: string;
    total: string;
  };
  panel: {
    title: string;
    total: string;
  };
  inverter: {
    title: string;
    total: string;
  };
  charge_controller: {
    title: string;
    total: string;
  };
  total_item_cost_price: number;
  total_item_sales_price: number;
  discount: string;
  status: string;
  views: string;
  date: string;
  img_src_sm: string;
  img_src_lg: string;
};

type UserDetails = {
  email: string;
  phone: string;
  name: string;
  amount: string;
};

type Customizations = {
  title: string;
  description: string;
  logo: string;
};

type Credentials = {
  public_key: string;
  type: string;
};

type PaymentDetails = {
  user_details: UserDetails;
  customizations: Customizations;
  credentials: Credentials;
};

type PackageDetailsResponse = {
  data: Package;
  message: string;
  status: boolean;
};
type OrderDetailsResponse = {
  data: Order;
  message: string;
  status: boolean;
};

type PaymentDetailsResponse = {
  data: PaymentDetails;
  message: string;
  status: boolean;
};

type PackageDetails = {
  uniq_id: string;
  total_battery_capacity: number;
  total_solar_panel_capacity: number;
  total_inverter_capacity: number;
  total_charge_controller_capacity: number;
  total_item_cost_price: number;
  total_item_sales_price: number;
  discount: number;
  total_items: number;
  package_name: string;
};

type UserCalculatedEnergy = {
  min_recommended_inverter_size: number;
  higher_inverter_size: number;
  recommended_battery_size: number;
  bellow_battery_size: number;
  higher_battery_size: number;
  recommended_panel_size: number;
  bellow_panel_size: number;
  higher_panel_size: number;
};

export type RequestedPackageDto = {
  status: boolean;
  message: string;
  user_calculated_energy: UserCalculatedEnergy;
  recomended_package: PackageDetails;
  lower_package: PackageDetails;
  high_package: PackageDetails;
};

export type RequestedPackageResponse = {
  data: RequestedPackageDto;
  message: string;
  status: boolean;
};

export const installersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getStates: builder.query<StateResponse, void>({
      query: () => ({
        url: `${ORDER_URL}/states/`,
        method: "GET",
      }),
    }),
    getFinancialInstitutions: builder.query<FinInstitutionsResponse, void>({
      query: () => ({
        url: `${ORDER_URL}/financial-institutions/`,
        method: "GET",
      }),
    }),
    getLgas: builder.query<LgaResponse, { state_id: string }>({
      query: ({ state_id }) => ({
        url: `${ORDER_URL}/lga/?state_id=${state_id}`,
        method: "GET",
      }),
    }),
    getPackages: builder.mutation({
      query: (params) => {
        const queryParams = new URLSearchParams();

        Object.entries(params).forEach(([key, value]) => {
          if (value !== null && value !== undefined) {
            queryParams.append(key, value.toString());
          }
        });

        return {
          url: `${ORDER_URL}/packages/?${queryParams.toString()}`,
          method: "GET",
        };
      },
    }),

    getPackageDetails: builder.query<
      PackageDetailsResponse,
      { uniq_id: string }
    >({
      query: ({ uniq_id }) => ({
        url: `${ORDER_URL}/package-details/?uniq_id=${uniq_id}`,
        method: "GET",
      }),
    }),

    createOrder: builder.mutation({
      query: (data) => ({
        url: `${ORDER_URL}/process`,
        method: "POST",
        body: {
          action: "place_order",
          package_uniq_id: data.package_uniq_id,
          installer_uniq_id: data.installer_uniq_id,
          lga_id: data.lga_id,
          name: data.name,
          payment_type: data.payment_type,
        },
      }),
    }),
    submitBank: builder.mutation({
      query: (data) => ({
        url: `${ORDER_URL}/process`,
        method: "POST",
        body: {
          action: "submit_financial_institution",
          financial_institution_uniq_id: data.financial_institution_uniq_id,
          order_id: data.order_id,
        },
      }),
    }),
    verifyPayment: builder.mutation({
      query: (data) => ({
        url: `${ORDER_URL}/process`,
        method: "POST",
        body: {
          action: "verify_payment",
          order_id: data.order_id,
        },
      }),
    }),

    getOrderDetails: builder.query<OrderDetailsResponse, { order_id: string }>({
      query: ({ order_id }) => ({
        url: `${ORDER_URL}/order-details/?order_id=${order_id}`,
        method: "GET",
      }),
    }),
    getPaymentDetails: builder.query<
      PaymentDetailsResponse,
      { order_id: string }
    >({
      query: ({ order_id }) => ({
        url: `${ORDER_URL}/payment-api/?order_id=${order_id}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetStatesQuery,
  useGetPackageDetailsQuery,
  useGetPackagesMutation,
  useGetLgasQuery,
  useCreateOrderMutation,
  useGetOrderDetailsQuery,
  useGetFinancialInstitutionsQuery,
  useSubmitBankMutation,
  useGetPaymentDetailsQuery,
  useVerifyPaymentMutation,
} = installersApiSlice;
