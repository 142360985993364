import React, { useState } from "react";
import imagesUrl from "../../../assets/images/imagesUrl";
import MaxWidthWrapper from "../../../components/max-width-wrapper";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "../../../components/ui/form";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../components/ui/popover";
import { Button } from "../../../components/ui/button";
import { cn } from "../../../lib/utils";
import { Check, ChevronsUpDown, Search } from "lucide-react";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "../../../components/ui/command";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { SearchInstallerSchema } from "../../../types/search-installer-schema";
import { z } from "zod";
import { Input } from "../../../components/ui/input";
import { useNavigate } from "react-router-dom";
import { State, useGetStatesQuery } from "../../../store/ordersApiSlice";

const Hero = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const { data, isLoading, refetch } = useGetStatesQuery();
  const states = data?.data || [];

  const form = useForm<z.infer<typeof SearchInstallerSchema>>({
    resolver: zodResolver(SearchInstallerSchema),
    defaultValues: {
      state_id: "",
      search: "",
    },
  });

  return (
    <MaxWidthWrapper className="px-0 md:px-4 mb-10 lg:mb-20">
      <div className="w-full h-fit relative top-0   md:h-[510px] ">
        <div className="flex flex-col-reverse md:flex-row md:items-center h-full w-full gap-8 ">
          <div className=" md:px-4  w-full md:w-[50%] flex flex-col gap-[42px]">
            <h1 className=" max-w-sm md:w-full md:px-0 text-[37px] sm:text-[47px] lg:text-[58px] opacity-[95%] leading-[44.4px] md:leading-[69.6px] text-center  md:text-left">
              Find Professional Installers for your solar Project
            </h1>
            <div className="px-4 md:px-0">
              <div className=" p-1 flex items-center rounded-md h-[43px] w-full border-[0.3px] border-[#003366]">
                <Form {...form}>
                  <form className="flex items-center w-full">
                    <FormField
                      control={form.control}
                      name="state_id"
                      render={({ field }) => (
                        <FormItem className="w-fit border-0 border-none flex flex-col p-0">
                          <Popover open={open} onOpenChange={setOpen}>
                            <PopoverTrigger asChild>
                              <FormControl>
                                <Button
                                  //   disabled={!!editMode}
                                  variant="outline"
                                  role="combobox"
                                  size={"sm"}
                                  className={cn(
                                    "bg-[#DEEDCC] !text-[#3D6F00] justify-between border-0 border-none",
                                    !field.value && "text-muted-foreground"
                                  )}
                                >
                                  {field.value
                                    ? states.find(
                                        (state: State) =>
                                          state.state_id === field.value
                                      )?.name
                                    : "All States"}
                                  <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                                </Button>
                              </FormControl>
                            </PopoverTrigger>
                            <PopoverContent className=" p-0">
                              <Command>
                                <CommandInput placeholder="Search state..." />
                                <CommandEmpty>No state found.</CommandEmpty>
                                <CommandGroup>
                                  <CommandList>
                                    {states.map((state: State) => (
                                      <CommandItem
                                        value={state.name}
                                        key={state.state_id}
                                        onSelect={() => {
                                          form.setValue(
                                            "state_id",
                                            state.state_id
                                          );
                                          setOpen(false);
                                        }}
                                      >
                                        <Check
                                          className={cn(
                                            "mr-2 h-4 w-4",
                                            state.state_id === field.value
                                              ? "opacity-100"
                                              : "opacity-0"
                                          )}
                                        />
                                        {state.name}
                                      </CommandItem>
                                    ))}
                                  </CommandList>
                                </CommandGroup>
                              </Command>
                            </PopoverContent>
                          </Popover>
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="search"
                      render={({ field }) => (
                        <FormItem>
                          <FormControl>
                            <Input
                              variant={"unstyled"}
                              className="border-0 border-none "
                              {...field}
                              placeholder="Search installers"
                              type={"text"}
                              autoComplete="current-password"
                              startContent={<Search className="w-4 h-4" />}
                            />
                          </FormControl>
                        </FormItem>
                      )}
                    />
                    <Button
                      onClick={() => {
                        const stateId = form.getValues("state_id");
                        const searchTerm = form.getValues("search");

                        let queryParams = new URLSearchParams();
                        if (stateId) queryParams.append("state_id", stateId);
                        if (searchTerm)
                          queryParams.append("search", searchTerm);

                        navigate(
                          `/search/installers?${queryParams.toString()}`
                        );
                      }}
                      size={"sm"}
                      className="ml-auto bg-[#3EB555] hover:bg-[#359C4A] "
                    >
                      Search
                    </Button>
                  </form>
                </Form>
              </div>
            </div>
          </div>
          <div className=" w-full relative md:w-[50%] h-[500px] md:h-full mb-auto md:mb-0">
            <div className="w-[100%] md:w-[90%] md:ml-auto h-full bg-[#D9D9D9] relative">
              <img
                src={imagesUrl.InstallersHero}
                className="mb-auto w-full h-full object-contain"
                alt="hero "
              />
            </div>
          </div>
        </div>
      </div>
    </MaxWidthWrapper>
  );
};

export default Hero;
