import React from "react";
import MaxWidthWrapper from "../../../../components/max-width-wrapper";
import Grid from "../../../../components/Grid";
import InstallerCard from "../../../../components/installer-card";

import { Installer } from "../../../../store/installersApiSlice";
import { CircleOff } from "lucide-react";
import { BeatLoader } from "react-spinners";

interface InstallersReelProps {
  installers?: Installer[];
  isLoading?: boolean;
}

const InstallersReel = (props: InstallersReelProps) => {
  const { installers, isLoading } = props;
  return (
    <MaxWidthWrapper className="pb-10">
      <div className=" bg-[#DEEDCC33] w-full h-full p-4 rounded-lg">
        <Grid className="grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
          {isLoading ? (
            <div className="col-span-4">
              <BeatLoader color="#3EB555" loading size={10} />
            </div>
          ) : installers ? (
            installers?.map((installer, idx) => (
              <Grid.Item key={idx} className="animate-fadeIn">
                <InstallerCard installer={installer} />
              </Grid.Item>
            ))
          ) : (
            <div className="w-full min-h-60  col-span-4 my-auto flex items-center justify-center">
              <div className="mx-auto flex flex-col space-y-4 items-center justify-center">
                <CircleOff color="#cccccc" className="w-12 h-12" />

                <p className="text-[#cccccc]">No data Found!</p>
              </div>
            </div>
          )}
        </Grid>

        {/* <Pagination className="mt-5">
          <PaginationContent>
            <PaginationItem>
              <PaginationPrevious href="#" className="bg-[#CCCCCC]" />
            </PaginationItem>
            <PaginationItem>
              <PaginationLink href="#">1</PaginationLink>
            </PaginationItem>
            <PaginationItem>
              <PaginationEllipsis />
            </PaginationItem>
            <PaginationItem>
              <PaginationNext className="bg-[#5CA600]" href="#" />
            </PaginationItem>
          </PaginationContent>
        </Pagination> */}
      </div>
    </MaxWidthWrapper>
  );
};

export default InstallersReel;
