import { Button } from "../../components/ui/button";
import NavTransparent from "../../components/nav-transparent";
import React from "react";
import { useNavigate } from "react-router-dom";

const HomePage = () => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.3)), url('/banner-image.png')`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        minHeight: "100vh",
        width: "100vw",
      }}
    >
      <NavTransparent />

      <div className="flex flex-col items-center justify-center w-full h-[100vh]">
        <h1 className="text-[46px] lg:text-7xl leading-[55.2px] lg:leading-[86.4px] font-[400] text-white">
          Clean Power all day long
        </h1>
        <Button
          onClick={() => navigate("/order-a-system")}
          size={"lg"}
          className="text-black mt-[100px] bg-[#3EB555] hover:bg-[#359C4A] px-12 py-6 text-xl"
        >
          Order Now
        </Button>
      </div>
    </div>
  );
};

export default HomePage;
