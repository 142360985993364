import React from "react";
import MaxWidthWrapper from "../../../../components/max-width-wrapper";

type BasicInfoProps = {
  phone: string | null;
  email: string | null;
  address: string | null;
  response_time: string | null;
  installations: string | null;
};
const BasicInformation = (props: BasicInfoProps) => {
  const { phone, email, address, response_time, installations } = props;

  return (
    <MaxWidthWrapper className="mb-5">
      <div className="max-w-2xl text-left flex flex-col space-y-3">
        <h3 className="text-2xl">Basic Information</h3>
        <div className="flex flex-col md:flex-row space-y-3 md:space-y-0 md:items-center md:space-x-20">
          <div className="">
            <p className="text-[#808080] text-sm">Phone number</p>
            <span className="text-[#2B2B2B] text-base">{phone}</span>
          </div>
          <div>
            <p className="text-[#808080] text-sm">Email</p>
            <span className="text-[#2B2B2B] text-base">{email}</span>
          </div>
        </div>
        <div>
          <p className="text-[#808080] text-sm">Address</p>
          <span className="text-[#2B2B2B] text-base">{address}</span>
        </div>
        <div className="flex items-center space-x-20">
          <div>
            <p className="text-[#808080] text-sm">Response time</p>
            <span className="text-[#2B2B2B] text-base">{response_time}</span>
          </div>
          <div>
            <p className="text-[#808080] text-sm">Completed projects</p>
            <span className="text-[#2B2B2B] text-base">{installations}</span>
          </div>
        </div>
      </div>
    </MaxWidthWrapper>
  );
};

export default BasicInformation;
