import React from "react";
import MaxWidthWrapper from "../../../../components/max-width-wrapper";

type ExpertiseProps = {
  expertise: {
    expertise_id: string;
    expertise_name: string;
  }[];
  about: string;
};
const Expertise = (props: ExpertiseProps) => {
  const { expertise, about } = props;
  return (
    <MaxWidthWrapper className="mb-5">
      <div className="max-w-3xl text-left flex flex-col space-y-3">
        <h3 className="text-2xl ">Expertise</h3>
        <div className="flex flex-col space-y-5 md:space-y-0 md:flex-row  md:space-x-32">
          <div>
            <p className="text-[#808080] text-sm">Area of expertise</p>
            <ul className="list-inside list-disc text-[#2B2B2B] text-base">
              {expertise?.map((expertise, idx) => (
                <li className="text-nowrap" key={idx}>
                  {expertise.expertise_name}
                </li>
              ))}
            </ul>
          </div>
          <div>
            <p className="text-[#808080] text-sm">About installer</p>
            <span className="text-[#2B2B2B] text-base">{about}</span>
          </div>
        </div>
      </div>
    </MaxWidthWrapper>
  );
};

export default Expertise;
