import React from "react";
import MaxWidthWrapper from "../../../../components/max-width-wrapper";
import imagesUrl from "../../../../assets/images/imagesUrl";
type SocialsProps = {
  facebook: string;
  instagram: string;
  youtube: string;
  linkedin: string;
  twitter: string;
  whatsapp: string;
};
const Socials = (props: SocialsProps) => {
  const { facebook, instagram, youtube, linkedin, twitter, whatsapp } = props;

  const isValidUrl = (url: string) => {
    try {
      return Boolean(new URL(url));
    } catch (e) {
      return false;
    }
  };

  const hasValidSocialLink =
    isValidUrl(facebook) ||
    isValidUrl(instagram) ||
    isValidUrl(youtube) ||
    isValidUrl(linkedin) ||
    isValidUrl(twitter) ||
    isValidUrl(whatsapp);

  return (
    <MaxWidthWrapper className="mb-5">
      <div className="max-w-2xl text-left flex flex-col space-y-3">
        <h3 className="text-2xl ">Socials</h3>

        {hasValidSocialLink ? (
          <div className="flex items-center space-x-4">
            {isValidUrl(whatsapp) && (
              <a href={whatsapp}>
                <img src={imagesUrl.Whatsapp} alt="whatsapp icon" />
              </a>
            )}

            {isValidUrl(twitter) && (
              <a href={twitter}>
                <img src={imagesUrl.Twitter} alt="twitter icon" />
              </a>
            )}

            {isValidUrl(instagram) && (
              <a href={instagram}>
                <img src={imagesUrl.Instagram} alt="instagram icon" />
              </a>
            )}

            {isValidUrl(facebook) && (
              <a href={facebook}>
                <img src={imagesUrl.Facebook} alt="facebook icon" />
              </a>
            )}

            {isValidUrl(youtube) && (
              <a href={youtube}>
                <img src={imagesUrl.Youtube} alt="youtube icon" />
              </a>
            )}

            {isValidUrl(linkedin) && (
              <a href={linkedin}>
                <img src={imagesUrl.Linkedin} alt="linkedin icon" />
              </a>
            )}
          </div>
        ) : (
          <p>Not available</p>
        )}
      </div>
    </MaxWidthWrapper>
  );
};

export default Socials;
