import {
  createApi,
  fakeBaseQuery,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";

const API_URI = "https://power-ring.coderstriangle.com/v1/";
const baseQuery = fetchBaseQuery({ baseUrl: API_URI });

export const apiSlice = createApi({
  baseQuery,
  tagTypes: ["Order, Installer"],
  endpoints: (builder) => ({}),
});
