import React, { useState } from "react";
import MaxWidthWrapper from "../../../../components/max-width-wrapper";
import { useNavigate } from "react-router-dom";
import imagesUrl from "../../../../assets/images/imagesUrl";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
} from "../../../../components/ui/form";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../../components/ui/popover";
import { Button } from "../../../../components/ui/button";
import { cn } from "../../../../lib/utils";
// import { states } from "../../../../config";
import { Check, ChevronsUpDown, Search } from "lucide-react";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "../../../../components/ui/command";
import { SearchInstallerSchema } from "../../../../types/search-installer-schema";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Input } from "../../../../components/ui/input";
import { useForm } from "react-hook-form";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "../../../../components/ui/select";
import { State, useGetStatesQuery } from "../../../../store/ordersApiSlice";
import { useIdStore } from "../../../../store/customer-store";

const Hero = ({
  onSearchChange,
  state_name,
}: {
  onSearchChange: (values: { state_id: string; search: string }) => void;
  state_name: string;
}) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const stateName = useIdStore((state) => state.readStateName());

  const { data, isLoading, refetch } = useGetStatesQuery();
  const states = data?.data || [];

  const form = useForm<z.infer<typeof SearchInstallerSchema>>({
    resolver: zodResolver(SearchInstallerSchema),
    defaultValues: {
      state_id: "",
      search: "",
    },
  });
  return (
    <MaxWidthWrapper className="mt-5 md:mt-10 mb-5 md:mb-10">
      <div className="w-full flex flex-col space-y-6">
        <div className="grid grid-cols-1 space-y-5 md:space-y-0 md:grid-cols-3">
          <div>
            <div
              onClick={() => navigate(-1)}
              className="flex group items-center space-x-3"
            >
              <img
                src={imagesUrl.Back}
                alt="back"
                className="w-10 h-10 transition-transform transform group-hover:scale-90"
              />
              <p className="cursor-pointer text-[20px] text-white font-[400] group-hover:text-[#3EB555]  group-hover:border-b-[#3EB555]">
                Go Back
              </p>
            </div>
          </div>
          <div className="flex flex-col items-center justify-center">
            <h1 className="text-white text-center text-[32px] leading-[38.4px] font-bold">
              Select Installer
            </h1>
            {stateName && (
              <p className="text-white">{`Installers in ${stateName} state`}</p>
            )}
          </div>
        </div>
        <div className="w-full mt-3 space-y-3 md:space-y-0 flex items-end flex-col md:flex-row md:items-center justify-between">
          <div className="">
            <div className=" p-1 bg-white flex items-center rounded-md h-[43px] w-full border-[0.3px] border-[#003366]">
              <Form {...form}>
                <form
                  className="flex items-center w-full"
                  onSubmit={form.handleSubmit((values) => {
                    onSearchChange(values);
                  })}
                >
                  <FormField
                    control={form.control}
                    name="state_id"
                    render={({ field }) => (
                      <FormItem className="w-fit border-0 border-none flex flex-col p-0">
                        <Popover open={open} onOpenChange={setOpen}>
                          <PopoverTrigger asChild>
                            <FormControl>
                              <Button
                                //   disabled={!!editMode}
                                variant="outline"
                                role="combobox"
                                size={"sm"}
                                className={cn(
                                  "bg-[#DEEDCC] !text-[#3D6F00] justify-between border-0 border-none",
                                  !field.value && "text-muted-foreground"
                                )}
                              >
                                {field.value
                                  ? states.find(
                                      (state: State) =>
                                        state.state_id === field.value
                                    )?.name
                                  : "All States"}
                                <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                              </Button>
                            </FormControl>
                          </PopoverTrigger>
                          <PopoverContent className=" p-0">
                            <Command>
                              <CommandInput placeholder="Search state..." />
                              <CommandEmpty>No state found.</CommandEmpty>
                              <CommandGroup>
                                <CommandList>
                                  {states.map((state: State) => (
                                    <CommandItem
                                      value={state.name}
                                      key={state.state_id}
                                      onSelect={() => {
                                        form.setValue(
                                          "state_id",
                                          state.state_id
                                        );
                                        setOpen(false);
                                      }}
                                    >
                                      <Check
                                        className={cn(
                                          "mr-2 h-4 w-4",
                                          state.state_id === field.value
                                            ? "opacity-100"
                                            : "opacity-0"
                                        )}
                                      />
                                      {state.name}
                                    </CommandItem>
                                  ))}
                                </CommandList>
                              </CommandGroup>
                            </Command>
                          </PopoverContent>
                        </Popover>
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="search"
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <Input
                            variant={"unstyled"}
                            className="border-0 border-none "
                            {...field}
                            placeholder="Search installers"
                            type={"text"}
                            autoComplete="current-password"
                            startContent={<Search className="w-4 h-4" />}
                          />
                        </FormControl>
                      </FormItem>
                    )}
                  />
                  <Button
                    // onClick={() => {
                    //   const stateId = form.getValues("state_id");
                    //   const searchTerm = form.getValues("search");

                    //   let queryParams = new URLSearchParams();
                    //   if (stateId) queryParams.append("state_id", stateId);
                    //   if (searchTerm) queryParams.append("search", searchTerm);

                    //   navigate(`/search/installers?${queryParams.toString()}`);
                    // }}
                    type="submit"
                    size={"sm"}
                    className="ml-auto bg-[#3EB555] hover:bg-[#359C4A] "
                  >
                    Search
                  </Button>
                </form>
              </Form>
            </div>
          </div>
          {/* <div className="flex items-center space-x-2">
            <span className="text-[18px] font-medium text-[#3EB555]">
              Sort by:
            </span>
            <Select>
              <SelectTrigger className="w-[180px]">
                <SelectValue className="text-[14px]" placeholder="Sort by" />
              </SelectTrigger>
              <SelectContent className="text-[14px]">
                <SelectGroup>
                  <SelectItem className="text-[14px]" value="experience">
                    Most experience
                  </SelectItem>
                  <SelectItem className="text-[14px]" value="rating">
                    Highest rating
                  </SelectItem>
                </SelectGroup>
              </SelectContent>
            </Select>
          </div> */}
        </div>
      </div>
    </MaxWidthWrapper>
  );
};

export default Hero;
