import React, { useEffect, useState } from "react";
import MaxWidthWrapper from "../../../components/max-width-wrapper";
import { Button } from "../../../components/ui/button";
import { Check, ChevronRight, ChevronsUpDown, Loader2 } from "lucide-react";
import { useNavigate } from "react-router-dom";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../components/ui/form";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { PaymentInformationSchema } from "../../../types/payment-information-schema";
import { Input } from "../../../components/ui/input";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../components/ui/popover";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../components/ui/select";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "../../../components/ui/command";
import { cn } from "../../../lib/utils";
import {
  Lga,
  useCreateOrderMutation,
  useGetLgasQuery,
} from "../../../store/ordersApiSlice";
import { skipToken } from "@reduxjs/toolkit/query";
import { IdState, useIdStore } from "../../../store/customer-store";
import { useGetInstallerQuery } from "../../../store/installersApiSlice";
import { toast } from "sonner";

const PlanSelector = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const [createOrder, { isLoading: createOrderLoading }] =
    useCreateOrderMutation();

  // Retrieve the stateId and installerId from Zustand store
  const stateId = useIdStore((state) => state.readStateId());
  const installerId = useIdStore((state) => state.readInstallerId());
  const packageId = useIdStore((state) => state.readPackageId());
  const customerName = useIdStore((state) => state.readCustomerName());
  const lgaId = useIdStore((state) => state.readLgaId());
  const paymentTypeId = useIdStore((state) => state.readPaymentTypeId());
  // const clearPackageId = useIdStore((state) => state.removePackageId());

  const setPaymentTypeId = useIdStore(
    (state: IdState) => state.setPaymentTypeId
  );
  let loadingToastId: string | number;

  const setCustomerName = useIdStore((state: IdState) => state.setCustomerName);
  const setLgaId = useIdStore((state: IdState) => state.setLgaId);
  // const setOrderId = useIdStore((state: IdState) => state.setOrderId);

  const {
    data: lgaData,
    isLoading: isLoadingLga,
    refetch: refetchLga,
  } = useGetLgasQuery(stateId ? { state_id: stateId } : skipToken);

  const lgas = lgaData?.data || [];

  const form = useForm<z.infer<typeof PaymentInformationSchema>>({
    resolver: zodResolver(PaymentInformationSchema),
    defaultValues: {
      name: "",
      payment_method: "",
      lga: "",
    },
  });

  const formValues = form.watch();

  useEffect(() => {
    if (customerName) {
      form.setValue("name", customerName);
    }
    if (lgaId) {
      form.setValue("lga", lgaId);
    }
    if (paymentTypeId) {
      form.setValue("payment_method", paymentTypeId);
    }
  }, []);

  useEffect(() => {
    if (formValues.payment_method) {
      console.log(formValues.payment_method, "payment type selected");
      setPaymentTypeId(formValues.payment_method);
    }
  }, [formValues.payment_method]);

  useEffect(() => {
    if (formValues.lga) {
      console.log(formValues.lga, "lga type selected");
      setLgaId(formValues.lga);
    }
  }, [formValues.lga]);

  useEffect(() => {
    if (formValues.name) {
      // console.log(formValues.name, "payment type selected");
      setCustomerName(formValues.name);
    }
  }, [formValues.name]);

  const handlePaymentInformation = async () => {
    // toast.info("Placing order. Please wait...");
    if (!packageId) {
      toast.error("You have not selected a package");
    } else if (!installerId) {
      toast.error("You have not selected an installer");
    } else if (!stateId) {
      toast.error("You have not selected state of residence");
    } else {
      // toast.info("Processing order. Please wait...");
      navigate("/create-account");
    }

    // try {
    //   const order = await createOrder({
    //     package_uniq_id: packageId,
    //     installer_uniq_id: installerId,
    //     lga_id: formValues.lga,
    //     name: formValues.name,
    //     payment_type: formValues.payment_method,
    //   }).unwrap();
    //   toast.success("Order placed successfully");

    //   console.log("Order created successfully:", order);
    //   setOrderId(order?.order_id);
    //   navigate(`/invoice-one-time/?order_id=${order?.order_id}`);
    // } catch (error) {
    //   console.error("Error creating order:", error);
    // }
  };

  const handleInstallmentPayment = async () => {
    // toast.info("Placing order. Please wait...");
    // console.log("Processing installment payment...");
    // try {
    //   const order = await createOrder({
    //     package_uniq_id: packageId,
    //     installer_uniq_id: installerId,
    //     lga_id: formValues.lga,
    //     name: formValues.name,
    //     payment_type: formValues.payment_method,
    //   }).unwrap();
    //   toast.success("Order placed successfully");
    //   console.log("Order created successfully:", order);
    //   setOrderId(order?.order_id);
    // } catch (error) {
    //   console.error("Error creating order:", error);
    // }
    // navigate("/create-account");
  };

  return (
    <MaxWidthWrapper>
      <div className="w-full   items-center justify-center">
        <div className="w-full md:w-[50%] mx-auto flex flex-col space-y-10">
          <h1 className="text-[32px] font-bold text-white">
            Payment Information
          </h1>
          <div className="w-full">
            <Form {...form}>
              <form
                onSubmit={form.handleSubmit(handlePaymentInformation)}
                className="flex flex-col space-y-3 items-start w-full"
              >
                <FormField
                  control={form.control}
                  name="name"
                  render={({ field }) => (
                    <FormItem className="w-full flex items-start flex-col">
                      <FormLabel className="text-white text-base">
                        Name{" "}
                      </FormLabel>

                      <FormControl>
                        <Input
                          {...field}
                          className="w-full  bg-[#DEEDCC]"
                          type="text"
                          placeholder="Your name"
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="lga"
                  render={({ field }) => (
                    <FormItem className="w-full border-0 border-none flex items-start flex-col p-0">
                      <FormLabel className="text-[#ffffff]">
                        Local Government
                      </FormLabel>
                      <Popover open={open} onOpenChange={setOpen}>
                        <PopoverTrigger asChild>
                          <FormControl>
                            <Button
                              //   disabled={!!editMode}
                              variant="outline"
                              role="combobox"
                              size={"sm"}
                              className={cn(
                                "bg-[#DEEDCC] w-full !text-primary font-[400] justify-between border-0 border-none",
                                !field.value && "text-muted-foreground"
                              )}
                            >
                              {field.value
                                ? lgas.find(
                                    (lga: Lga) => lga.local_id === field.value
                                  )?.name
                                : "Select LGA"}
                              <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                            </Button>
                          </FormControl>
                        </PopoverTrigger>
                        <PopoverContent className=" p-0">
                          <Command>
                            <CommandInput placeholder="Search LGA..." />
                            <CommandEmpty>
                              You have not selected a state
                            </CommandEmpty>
                            <CommandGroup>
                              <CommandList>
                                {lgas.map((lga: Lga) => (
                                  <CommandItem
                                    value={lga.name}
                                    key={lga.local_id}
                                    onSelect={() => {
                                      form.setValue("lga", lga.local_id);
                                      setOpen(false);
                                    }}
                                  >
                                    <Check
                                      className={cn(
                                        "mr-2 h-4 w-4",
                                        lga.local_id === field.value
                                          ? "opacity-100"
                                          : "opacity-0"
                                      )}
                                    />
                                    {lga.name}
                                  </CommandItem>
                                ))}
                              </CommandList>
                            </CommandGroup>
                          </Command>
                        </PopoverContent>
                      </Popover>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="payment_method"
                  render={({ field }) => (
                    <FormItem className="w-full border-0 border-none flex items-start flex-col p-0">
                      <FormLabel className="text-white text-left">
                        How do you want to pay?
                      </FormLabel>
                      <Select
                        onValueChange={(value) => {
                          field.onChange(value);
                          // setUseNationalGrid(value);
                        }}
                        defaultValue={field.value}
                        value={field.value}
                      >
                        <FormControl className="bg-[#DEEDCC] w-full">
                          <SelectTrigger>
                            <SelectValue placeholder="Select" />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          <SelectItem value="1">One-time payment</SelectItem>
                          <SelectItem value="2">Installment Payment</SelectItem>
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <div className="w-full flex items-center justify-center">
                  <Button
                    type="submit"
                    disabled={!form.formState.isDirty}
                    className="w-full font-bold text-black bg-[#3EB555] hover:bg-[#359C4A]"
                  >
                    {createOrderLoading ? (
                      <Loader2 className="h-4 w-4 animate-spin text-white" />
                    ) : (
                      "Proceed"
                    )}
                  </Button>
                </div>
              </form>
            </Form>
          </div>
        </div>
      </div>
    </MaxWidthWrapper>
  );
};

export default PlanSelector;
