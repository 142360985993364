import React from "react";
import imagesUrl from "../../assets/images/imagesUrl";
import { Button } from "../../components/ui/button";
import MaxWidthWrapper from "../../components/max-width-wrapper";

const Content = () => {
  return (
    <MaxWidthWrapper>
      <div className="w-full h-full py-10 ">
        <div className="flex flex-col items-center space-y-6">
          <img
            src={imagesUrl.CheckFill}
            alt="check fill"
            aria-hidden="true"
            className="h-[102px] w-[102px]"
          />
          <h1 className="font-bold text-[32px] text-center text-white">
            Thanks for your order
          </h1>
          <p className="text-[#F2F2F2] text-[20px] font-medium w-full max-w-full md:max-w-prose">
            We have sent your order details to your email. Please check your
            email to confirm
          </p>

          <Button className="text-black  bg-[#3EB555] hover:bg-[#359C4A]">
            Go back home
          </Button>
        </div>
      </div>
    </MaxWidthWrapper>
  );
};

export default Content;
