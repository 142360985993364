import { Link } from "react-router-dom";
import { cn } from "../lib/utils";
import InstallerCard from "./installer-card";
import MaxWidthWrapper from "./max-width-wrapper";
import { ScrollArea, ScrollBar } from "./ui/scroll-area";
import { Installer } from "../store/installersApiSlice";

interface InstallersReelProps {
  title?: string;
  subtitle?: string;
  href?: string;
  className?: string;
  installers?: Installer[];
  isLoading?: boolean;
}

const InstallerReel = (props: InstallersReelProps) => {
  const { title, subtitle, href, className, installers, isLoading } = props;
  return (
    <MaxWidthWrapper className="mb-10 lg:mb-20">
      <section className={cn(" h-fit ", className)}>
        <div className="flex items-center justify-between mb-3">
          <div className="max-w-2xl  lg:max-w-4xl lpx-0">
            {title ? (
              <h1 className="capitalize text-2xl font-semibold text-gray-900">
                {title}
              </h1>
            ) : null}
            {subtitle ? (
              <p className="mt-2 text-sm text-muted-foreground">{subtitle}</p>
            ) : null}
          </div>

          {/* {href ? (
            <Link
              to={href}
              className="text-base font-medium text-[#3EB555] hover:text-[#359C4A] "
            >
              See more <span aria-hidden="true">&#10095;</span>
            </Link>
          ) : null} */}
        </div>

        <ScrollArea className="w-full whitespace-nowrap ">
          <div className="flex w-max space-x-4 py-4">
            {installers ? (
              installers.map((installer: Installer, idx) => (
                <div key={idx} className="w-[289px] ">
                  <InstallerCard installer={installer} />
                </div>
              ))
            ) : (
              <p>Not available yet</p>
            )}
          </div>
          <ScrollBar orientation="horizontal" />
        </ScrollArea>
      </section>
    </MaxWidthWrapper>
  );
};

export default InstallerReel;
