import React from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
} from "react-router-dom";

// import ProtectedRoutes from "./ProtectedRoutes";
import AppCrashPage from "../pages/errors/app-crash";
import HomePage from "../pages/home";
import InstallersPage from "../pages/installers";
import InstallerDetailPage from "../pages/installers/detail/installer-detail-page";
import SearchInstallerPage from "../pages/installers/search-installer";
import PaymentPlanPage from "../pages/payment-plan";
import InvoiceOneTimePage from "../pages/invoice-one-time";
import PaymentComplete from "../pages/payment-complete";
import OrderSystem from "../pages/order-a-system";
import CreateAccountPage from "../pages/create-account";
import SelectBankPage from "../pages/bank-selection";
import OrderCompletePage from "../pages/order-complete";
import MakePaymentPage from "../pages/make-payment";

const RouteConfig = createBrowserRouter(
  createRoutesFromElements(
    <Route
      errorElement={
        <div>
          <AppCrashPage />
        </div>
      }
    >
      <Route path="/" element={<HomePage />} />
      <Route path="/select-payment-plan" element={<PaymentPlanPage />} />
      <Route path="/order-a-system" element={<OrderSystem />} />
      <Route path="/invoice-one-time" element={<InvoiceOneTimePage />} />
      <Route path="/payment-complete" element={<PaymentComplete />} />
      <Route path="/make-payment" element={<MakePaymentPage />} />
      <Route path="/order-complete" element={<OrderCompletePage />} />
      <Route path="/create-account" element={<CreateAccountPage />} />
      <Route path="/select-bank" element={<SelectBankPage />} />
      <Route path="/installers" element={<InstallersPage />} />
      <Route
        path="/installers/:installerId"
        element={<InstallerDetailPage />}
      />
      <Route path="/search/installers" element={<SearchInstallerPage />} />
    </Route>
  )
);

export default RouteConfig;
