import React from "react";
import NavTransparent from "../../components/nav-transparent";
import Invoice from "./components/invoice";
import { useGetOrderDetailsQuery } from "../../store/ordersApiSlice";
import { skipToken } from "@reduxjs/toolkit/query";
import { useLocation } from "react-router-dom";
import { useIdStore } from "../../store/customer-store";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const InvoiceOneTimePage = () => {
  const query = useQuery();
  const order_id = query.get("order_id");

  const { data, isLoading, refetch } = useGetOrderDetailsQuery(
    order_id ? { order_id: order_id } : skipToken
  );

  const orderDetails = data?.data;

  console.log(orderDetails, "order details");

  return (
    <div
      className="pb-10"
      style={{
        backgroundImage: `linear-gradient(to bottom, rgba(2, 43, 38, 0.5), rgba(2, 43, 38, 1)), url('/banner-image.png')`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        minHeight: "100vh",
        width: "100vw",
      }}
    >
      <NavTransparent />

      <Invoice orderDetails={orderDetails} />
    </div>
  );
};

export default InvoiceOneTimePage;
