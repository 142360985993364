import { useNavigate } from "react-router-dom";
import MaxWidthWrapper from "../../../components/max-width-wrapper";
import imagesUrl from "../../../assets/images/imagesUrl";

const Hero = () => {
  const navigate = useNavigate();

  return (
    <MaxWidthWrapper className="mt-5 md:mt-10 mb-10">
      <div className="w-full flex flex-col space-y-6">
        <div className="grid grid-cols-1 space-y-5 md:space-y-0 md:grid-cols-3">
          <div>
            <div
              onClick={() => navigate("/order-a-system")}
              className="cursor-pointer group w-fit flex items-center space-x-3"
            >
              <img
                src={imagesUrl.Back}
                alt="back"
                className="w-10 h-10 transition-transform transform group-hover:scale-90"
              />
              <p className="cursor-pointer text-[20px] text-white font-[400] group-hover:text-[#3EB555]  group-hover:border-b-[#3EB555]">
                Go Back
              </p>
            </div>
          </div>
        </div>
      </div>
    </MaxWidthWrapper>
  );
};

export default Hero;
