import { apiSlice } from "./apiSlice";

const AUTH_URL = "auth";

export type UserDTO = {
  order_id: string;
  phone: string;
  email: string;
  password: string;
};

const getDeviceInfo = () => {
  const userAgent = navigator.userAgent;
  if (/iPhone/.test(userAgent)) return "iPhone";
  if (/iPad/.test(userAgent)) return "iPad";
  if (/Android/.test(userAgent)) return "Android";
  return "Desktop"; // default to desktop
};

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createAccount: builder.mutation({
      query: (data) => {
        const device = getDeviceInfo();

        return {
          url: `${AUTH_URL}/signup/`,
          method: "POST",
          body: {
            phone: data.phone,
            email: data.email,
            password: data.password,
            browser: "chrome",
            device: "Windows 11",
            package_uniq_id: data.package_uniq_id,
            installer_uniq_id: data.installer_uniq_id,
            lga_id: data.lga_id,
            name: data.name,
            payment_type: data.payment_type,
          },
        };
      },
    }),
  }),
});
export const { useCreateAccountMutation } = authApiSlice;
