import React, { useEffect, useState } from "react";
import NavTransparent from "../../../components/nav-transparent";
import Hero from "./components/hero";
import InstallersReel from "./components/installers-reel";
import { useSearchInstallersMutation } from "../../../store/installersApiSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetStatesQuery } from "../../../store/ordersApiSlice";

const SearchInstallerPage = () => {
  const { search } = useLocation();
  const [searchInstallers, { isLoading: searchInstallerLoading }] =
    useSearchInstallersMutation();
  const [installers, setInstallers] = useState([]);
  const { data, isLoading, refetch } = useGetStatesQuery();
  const states = data?.data || [];
  const [selectedStateName, setSelectedStateName] = useState("");

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useState({
    state_id: "",
    search: "",
  });
  // const queryParams = new URLSearchParams(search);
  // const stateId = queryParams.get("state_id") || null;
  // const searchTerm = queryParams.get("search") || null;

  const queryParams = new URLSearchParams(search);
  const stateIdFromURL = queryParams.get("state_id") || "";
  const searchTermFromURL = queryParams.get("search") || "";

  // console.log(stateId, searchTerm, "search params");

  // useEffect(() => {
  //   const fetchInstallers = async () => {
  //     const response = await searchInstallers({
  //       state: stateId,
  //       search: searchTerm,
  //       limit: 10,
  //     }).unwrap();
  //     setInstallers(response.data);
  //   };

  //   fetchInstallers();
  // }, [stateId, searchTerm, searchInstallers]);

  // useEffect(() => {
  //   const fetchInstallers = async () => {
  //     const response = await searchInstallers({
  //       state: searchParams.state_id || stateIdFromURL,
  //       search: searchParams.search || searchTermFromURL,
  //       limit: 10,
  //     }).unwrap();
  //     setInstallers(response.data);
  //   };

  //   fetchInstallers();
  // }, [searchParams, stateIdFromURL, searchTermFromURL, searchInstallers]);

  useEffect(() => {
    const fetchInstallers = async () => {
      const state = searchParams.state_id || stateIdFromURL;
      const search = searchParams.search || searchTermFromURL;

      console.log("Fetching installers with params:", { state, search });

      try {
        const response = await searchInstallers({
          state,
          search,
          limit: 10,
        }).unwrap();

        console.log("API response:", response);
        setInstallers(response.data);

        const stateName =
          states.find((state) => state.state_id === stateIdFromURL)?.name || "";
        setSelectedStateName(stateName);
      } catch (error) {
        console.error("Failed to fetch installers:", error);
      }
    };

    fetchInstallers();
  }, [
    searchParams.state_id,
    stateIdFromURL,
    searchTermFromURL,
    searchInstallers,
    states,
    searchParams.search,
  ]);

  console.log(installers, "installers");

  const handleSearchChange = (newSearchParams: {
    state_id: string;
    search: string;
  }) => {
    console.log(newSearchParams, "from hero search");
    setSearchParams(newSearchParams);

    navigate(
      `?state_id=${newSearchParams.state_id}&search=${newSearchParams.search}`
    );
  };

  return (
    <div
      style={{
        backgroundImage: `linear-gradient(to bottom, rgba(2, 43, 38, 0.5), rgba(2, 43, 38, 1)), url('/banner-image.png')`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        minHeight: "100vh",
        width: "100vw",
      }}
    >
      <NavTransparent />
      <Hero
        onSearchChange={handleSearchChange}
        state_name={selectedStateName}
      />
      <InstallersReel
        installers={installers}
        isLoading={searchInstallerLoading}
      />
    </div>
  );
};

export default SearchInstallerPage;

// import React, { useEffect, useState } from "react";
// import { useNavigate, useLocation } from "react-router-dom"; // Import useNavigate
// import NavTransparent from "../../../components/nav-transparent";
// import Hero from "./components/hero";
// import InstallersReel from "./components/installers-reel";
// import { useSearchInstallersMutation } from "../../../store/installersApiSlice";

// const SearchInstallerPage = () => {
//   const { search } = useLocation();
//   const navigate = useNavigate(); // Initialize useNavigate
//   const [searchInstallers, { isLoading: searchInstallerLoading }] = useSearchInstallersMutation();
//   const [installers, setInstallers] = useState([]);
//   const [searchParams, setSearchParams] = useState({
//     state_id: "",
//     search: "",
//   });

//   // Parse URL parameters when the component mounts or when the URL changes
//   useEffect(() => {
//     const queryParams = new URLSearchParams(search);
//     const stateIdFromURL = queryParams.get("state_id") || "";
//     const searchTermFromURL = queryParams.get("search") || "";

//     setSearchParams({
//       state_id: stateIdFromURL,
//       search: searchTermFromURL,
//     });
//   }, [search]);

//   useEffect(() => {
//     const fetchInstallers = async () => {
//       const { state_id, search } = searchParams;

//       console.log("Fetching installers with params:", { state_id, search });

//       try {
//         const response = await searchInstallers({
//           state: state_id,
//           search,
//           limit: 10,
//         }).unwrap();

//         console.log("API response:", response);
//         setInstallers(response.data);
//       } catch (error) {
//         console.error("Failed to fetch installers:", error);
//       }
//     };

//     // Call fetchInstallers if state_id or search changes
//     if (searchParams.state_id || searchParams.search) {
//       fetchInstallers();
//     }
//   }, [searchParams, searchInstallers]);

//   console.log(installers, "installers");

//   const handleSearchChange = (newSearchParams) => {
//     console.log("New search params received:", newSearchParams);
//     setSearchParams(newSearchParams);

//     // Update the URL with the new search parameters without reloading the page
//     navigate(`?state_id=${newSearchParams.state_id}&search=${newSearchParams.search}`);
//   };

//   return (
//     <div
//       style={{
//         backgroundImage: `linear-gradient(to bottom, rgba(2, 43, 38, 0.5), rgba(2, 43, 38, 1)), url('/banner-image.png')`,
//         backgroundSize: "cover",
//         backgroundPosition: "bottom",
//         backgroundRepeat: "no-repeat",
//         height: "100%",
//         width: "100vw",
//       }}
//     >
//       <NavTransparent />
//       <Hero onSearchChange={handleSearchChange} />
//       <InstallersReel installers={installers} />
//     </div>
//   );
// };

// export default SearchInstallerPage;
