import React, { useEffect } from "react";
import MaxWidthWrapper from "../../../components/max-width-wrapper";
import {
  Order,
  OrderItem,
  useGetOrderDetailsQuery,
  useGetPaymentDetailsQuery,
  useVerifyPaymentMutation,
} from "../../../store/ordersApiSlice";
import { skipToken } from "@reduxjs/toolkit/query";
import { IdState, useIdStore } from "../../../store/customer-store";
import { PaystackButton } from "react-paystack";
import { cn } from "../../../lib/utils";
import { useNavigate } from "react-router-dom";
import { Loader2 } from "lucide-react";
import imagesUrl from "../../../assets/images/imagesUrl";
import { toast } from "sonner";

const Content = () => {
  const orderId = useIdStore((state: IdState) => state.readOrderId());

  const { data, isLoading, refetch } = useGetPaymentDetailsQuery(
    orderId ? { order_id: orderId } : skipToken
  );

  const [verifyPayment, { isLoading: verifyPaymentLoading }] =
    useVerifyPaymentMutation();

  const navigate = useNavigate();

  const paymentDetails = data?.data;

  const {
    data: orderData,
    isLoading: orderDataLoading,
    refetch: refetchOrderDabta,
  } = useGetOrderDetailsQuery(orderId ? { order_id: orderId } : skipToken);

  const orderDetails = orderData?.data;

  const customerPhone = useIdStore((state) => state.readCustomerPhone());
  const customerEmail = useIdStore((state) => state.readCustomerEmail());
  const customerName = useIdStore((state) => state.readCustomerName());

  const removeInstallerId = useIdStore((state) => state.removeInstallerId);
  const removePackageId = useIdStore((state) => state.removePackageId);
  const removeOrderId = useIdStore((state) => state.removeOrderId);
  const removeCustomerName = useIdStore((state) => state.removeCustomerName);
  const removeStateId = useIdStore((state) => state.removeStateId);
  const removeStateName = useIdStore((state) => state.removeStateName);
  const removeCustomerPhone = useIdStore((state) => state.removeCustomerPhone);
  const removeCustomerEmail = useIdStore((state) => state.removeCustomerEmail);

  console.log(paymentDetails, "payment details");
  const handlePaymentSuccess = async () => {
    if (orderId) {
      try {
        const response = await verifyPayment({
          order_id: orderId,
        }).unwrap();

        console.log("Verified payment:", response);

        removeInstallerId();
        removeOrderId();
        removeCustomerName();
        removePackageId();
        removeStateId();
        removeStateName();
        removeCustomerPhone();
        removeCustomerEmail();

        navigate("/order-complete");
      } catch (error) {
        console.error("Error verifying payment:", error);
      }
    } else {
      console.log("You do not have an existing order");
    }
  };

  const componentProps = {
    email: customerEmail || "",
    amount: 100 * 100,
    reference: orderId || "",
    metadata: {
      // cancel_action: "https://your-cancel-url.com",
      username: customerName || "",
      phone: customerPhone || "",

      custom_fields: [
        {
          display_name: paymentDetails?.customizations?.description ?? "",
          variable_name: "cart_items",
          value: orderDetails?.order_items?.map((item: OrderItem) => ({
            title: item.name,
            quantity: item.qty,
            price: item.price,
          })),
        },
      ],
      //   first_name: username,
    },
    publicKey: paymentDetails?.credentials?.public_key ?? "",
    text: "Continue to payment",
    onSuccess: handlePaymentSuccess,
    onClose: () => {},
  };

  useEffect(() => {
    if (!orderId) {
      toast.error("You do not have an existing order");
    }
  }, []);

  return (
    <MaxWidthWrapper>
      <div className="w-full  h-full items-center justify-center">
        <div className="w-full h-full md:w-[50%] mx-auto flex flex-col space-y-10">
          <h1 className="text-[32px] font-bold text-white">Payment Gateway</h1>
          <div className="flex items-center justify-center h-[280px] rounded-xl w-full  p-4 bg-[#DEEDCC33]">
            {orderDataLoading ? (
              <Loader2 className="h-4 w-4 animate-spin text-white" />
            ) : (
              <div className="">
                <PaystackButton
                  disabled={!orderId}
                  className={cn("paystack-button px-6")}
                  {...componentProps}
                />

                <div className=" items-center justify-center mx-auto relative h-fit w-32 mt-5">
                  <img
                    className="object-contain object-center"
                    src={imagesUrl.PaymentOptions}
                    alt="payment options"
                  />
                </div>

                <div className=" flex items-center justify-center flex-col space-y-2 mt-5">
                  <p className="italic text-[14px] text-[#cccccc]">
                    Powered By
                  </p>
                  <img
                    src={imagesUrl.Paystack}
                    className="w-24"
                    alt="paystack"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </MaxWidthWrapper>
  );
};

export default Content;
